<template>
  <div  class="center mt-80px">
<h1>Здесь страница всех Полезностей</h1>
  </div>
</template>


<script>
import {mapState} from 'vuex';


export default {
  name: 'HelpfulView',

  components: {
  },
  computed: {
  },

  methods: {
  },
}
</script>