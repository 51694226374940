<template>
  <div class="center mt-80px">
    <h1>Здесь страница всех Контактов</h1>
  </div>
</template>


<script>
import {mapState} from 'vuex';


export default {
  name: 'ContactsView',

  components: {},
  computed: {},

  methods: {},
}
</script>