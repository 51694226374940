<script>

</script>

<template>
  <div class="center mt-80px">
    <h1>404</h1>
  </div>
</template>

