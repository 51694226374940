import axios from 'axios';

export default {
  state: {
    cats: [],
  },
  getters: {
    cats: (state) => state.cats,
  },
  actions: {
    async fetchCats({ commit }) {
      const response = await axios.get('http://localhost:8081/api/cats');
      commit('setCats', response.data);
    },
  },
  mutations: {
    setCats(state, cats) {
      state.cats = cats;
    },
  },
};